(<template>
  <div class="change-pass">
    <change-form />
  </div>
</template>)

<script>
  import ChangePass from '@/components/auth_components/ChangePassForm';

  export default {
    components: {
      'change-form': ChangePass
    }
  };
</script>

<style scoped>
  .change-pass {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
  }
</style>
