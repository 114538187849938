(<template>
  <section :class="{'in-progress': progressActive}"
           class="change-form is-progress-bar">
    <div v-if="success"
         class="change-form__success-cont">
      <p>{{ $gettext('Your password has been updated!') }}</p>
    </div>
    <h1 class="change-form__title">
      <span>{{ $gettext('Change password') }}</span>
    </h1>
    <form class="change-form__form"
          @submit.prevent.stop="sumbitNewPass">
      <sk-input :description="text1"
                :placeholder="text1"
                :preselected-value="oldPass"
                :margin-bottom="true"
                :icon="false"
                :input-icon="'password'"
                :type="'password'"
                :autofocus="true"
                :autocomplete="false"
                :validation-name="oldPassErrorsName"
                @fieldvaluechanged="changeOldPass" />
      <sk-input :description="text2"
                :placeholder="text2"
                :preselected-value="newPass"
                :margin-bottom="true"
                :icon="false"
                :input-icon="'password'"
                :type="'password'"
                :autocomplete="false"
                :validation-name="newPassErrorsName"
                @fieldvaluechanged="changeNewPass" />
      <sk-input :description="text3"
                :placeholder="text3"
                :preselected-value="confirmPass"
                :margin-bottom="true"
                :icon="false"
                :input-icon="'password'"
                :type="'password'"
                :autocomplete="false"
                :validation-name="confirmPassErrorsName"
                @fieldvaluechanged="changeConfirmPass" />
      <button :class="{'is-disabled': disableSubmit}"
              class="sk-btn sk-btn--default change-form__btn">{{ $gettext('Update password') }}</button>
    </form>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        oldPass: '',
        oldPassErrors: [],
        oldPassErrorsName: 'oldPass',
        newPass: '',
        newPassErrors: [],
        newPassErrorsName: 'newPass',
        confirmPass: '',
        confirmPassErrors: [],
        confirmPassErrorsName: 'confirmPass',
        progressActive: false,
        success: false
      };
    },
    computed: {
      ...mapGetters({
        userUid: 'UserInfoStore/userUid'
      }),
      disableSubmit() {
        return !this.oldPass.trim()
          || this.oldPass.trim().length < 8
          || !this.newPass.trim()
          || this.newPass.trim().length < 8
          || this.confirmPass.trim() !== this.newPass.trim();
      },
      text1() { return this.$gettext('Old password'); },
      text2() { return this.$gettext('New password'); },
      text3() { return this.$gettext('New password confirmation'); }
    },
    methods: {
      changeOldPass(value) {
        this.oldPass = value;
      },
      changeNewPass(value) {
        this.newPass = value;
      },
      changeConfirmPass(value) {
        this.confirmPass = value;
      },
      showSuccess() {
        this.oldPass = '';
        this.newPass = '';
        this.confirmPass = '';
        this.success = true;
      },
      sumbitNewPass() {
        this.oldPassErrors = [];
        this.newPassErrors = [];
        this.confirmPassErrors = [];

        if (!this.oldPass.trim()) {
          this.oldPassErrors.push(this.$gettext('Please reenter your old password correctly'));
        } else if (this.oldPass.trim().length < 8) {
          this.oldPassErrors.push(this.$gettext('Password is too short (minimum is 8 characters)'));
        }
        if (!this.newPass.trim()) {
          this.newPassErrors.push(this.$gettext('Please enter a new password'));
        } else if (this.newPass.trim().length < 8) {
          this.newPassErrors.push(this.$gettext('Your new password was too short (minimum is 8 characters)'));
        }
        if (this.confirmPass.trim() !== this.newPass.trim()) {
          this.confirmPassErrors.push(this.$gettext('Please check your new password and password confirmation'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.oldPassErrorsName, errors: this.oldPassErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.newPassErrorsName, errors: this.newPassErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.confirmPassErrorsName, errors: this.confirmPassErrors});

        if (!this.disableSubmit) {
          const form = new FormData();

          form.append('old_password', this.oldPass);
          form.append('password', this.newPass);
          form.append('password_confirmation', this.confirmPass);

          this.progressActive = true;
          this.$store.dispatch('sendNewPass', form).then((data) => {
            this.$store.dispatch('TTAuthStore/logIn', {token: data.token, uid: this.userUid});
            this.progressActive = false;
            this.showSuccess();
          }).catch(() => {
            this.progressActive = false;
            this.oldPassErrors.push(this.$gettext('Please reenter your old password correctly'));
          });
        }
      }
    }
  };
</script>

<style scoped>
  .change-form {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 20px auto 0;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  @media (max-width: 767px) {
    .change-form {
      flex-grow: 1;
      max-width: initial;
      margin: 0;
    }
  }

  .change-form__title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #f4f6f8;
    font-weight: normal;
    font-size: 18px;
  }

  .change-form__form {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .change-form__btn {
    margin-top: 30px;
  }

  .change-form__success-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    background-color: #ff5b24;
    background-color: var(--color-secondary);
    color: #fff;
    text-align: center;
  }
</style>
